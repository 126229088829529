<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to {
  transform: scale(0.7);
  opacity: 0;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scale(1.3);
  opacity: 0;
}
</style>
<template>
  <div>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ __("Por favor aguarde", "leads") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        color="secondary"
        dark
        expand-on-hover
        :mini-variant.sync="is_mini"
      >
        <v-list nav dense>
          <template>
            <v-list-item link to="/">
              <v-list-item-action>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <ScopeProvider scope="contacts.read">
              <v-list-item link to="/contacts">
                <v-list-item-action>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Contatos", "contact")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <ScopeProvider scope="leads.read">
              <v-list-item link to="/leads">
                <v-list-item-action>
                  <v-badge
                    :content="unread_lead_notifications"
                    :value="unread_lead_notifications"
                    color="green"
                    overlap
                  >
                    <v-icon>mdi-briefcase</v-icon>
                  </v-badge>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Leads", "leads")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <ScopeProvider scope="leads.my_schedulings.read">
              <v-list-item link to="/my_schedulings">
                <v-list-item-action>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalize($tc("model.my_schedulings_page_title"))
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <ScopeProvider scope="info_requests.read">
              <v-list-item link to="/info_requests">
                <v-list-item-action>
                  <v-badge
                    :content="my_info_requests.length"
                    :value="my_info_requests.length"
                    color="green"
                    overlap
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-badge>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalize(
                      $tc(
                        "model.information_request_information_requests_title"
                      )
                    )
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <ScopeProvider scope="letters.read">
              <v-list-item link to="/letters">
                <v-list-item-action>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalize($tc("model.letter_page_title"))
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <v-list-group color="white" dark prepend-icon="mdi-eye" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Supervisão", "supervision")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link to="/supervision" exact>
                <v-list-item-content>
                  <v-list-item-title> Notificações </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/supervision/unity-distribution">
                <v-list-item-content>
                  <v-list-item-title> Distribuição Unidades </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-divider></v-divider>

            <v-list-group color="white" dark prepend-icon="mdi-robot" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Automações", "admin_app")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <div>
                <v-list-item link to="/lusitania/robots/jobs">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $capitalize($tc("model.automation_lusitania_title")) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div>
                <v-list-item link to="/image_predict">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $capitalize($tc("model.image_predict_page_title")) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <ScopeProvider scope="massive_actions.eps.read">
                <v-list-item link :to="{ name: 'automations_eps' }">
                  <v-list-item-content>
                    <v-list-item-title> EPS </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </ScopeProvider>
            </v-list-group>

            <v-divider></v-divider>

            <v-list-group color="white" dark prepend-icon="mdi-earth" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Universo HCM", "admin_app") }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link to="/hcm_universe/portal_companhias">
                <v-list-item-content>
                  <v-list-item-title> Portal Companhias </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-divider></v-divider>

            <ScopeProvider scope="internationalization.read">
              <v-list-item link to="/I18N">
                <v-list-item-action>
                  <v-icon>mdi-translate</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalize($tc("model.i18n_page_title"))
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>

            <v-divider></v-divider>

            <v-list-group
              color="white"
              dark
              no-action
              prepend-icon="mdi-account-tie"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Entidades", "admin_app")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <ScopeProvider scope="teams.read">
                <v-list-item link to="/teams">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $capitalize($tc("model.teams_teams_title")) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-account-hard-hat</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>

              <ScopeProvider scope="unities.read">
                <v-list-item link to="/unities">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $capitalize($tc("model.unities_unities"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-account-hard-hat</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>

              <ScopeProvider scope="service_managers.read">
                <v-list-item link to="/managers">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      __("Gestores", "managers")
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>
            </v-list-group>

            <v-list-group color="white" dark prepend-icon="mdi-lock" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    __("Acesso", "admin_app")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <ScopeProvider scope="auth.users.read">
                <v-list-item link to="/users">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $capitalize($tc("model.users_user_title")) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>

              <ScopeProvider scope="auth.scopes.read">
                <v-list-item link to="/scopes">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $capitalize($tc("model.scopes_scopes_title"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>

              <ScopeProvider scope="auth.roles.read">
                <v-list-item link to="/roles">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $capitalize($tc("model.roles_roles_title"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                    <v-icon>mdi-account-tie</v-icon>
                  </v-list-item-action> -->
                </v-list-item>
              </ScopeProvider>
            </v-list-group>

            <ScopeProvider scope="configs.read">
              <v-list-item link to="/configs">
                <v-list-item-action>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("Configurações", "settings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </ScopeProvider>
          </template>
        </v-list>
        <template v-slot:append>
          <v-list>
            <v-list-item link to="/logout">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div class="my-4 text-center">
            <span
              class="grey--text text--lighten-1 font-weight-bold text-caption"
              >{{ appVersion }}</span
            >
          </div>
        </template>
      </v-navigation-drawer>
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        elevation="1"
        clipped-right
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
          <h1>
            <v-img src="@/assets/logo.png" width="170px"></v-img>
          </h1>
        </v-toolbar-title>
        <v-spacer />
        <AppSearchBar />
        <VideoCallsBarIcon></VideoCallsBarIcon>
        <reminder-menu v-model="reminder_menu" bottom offset-y />
        <LanguagePicker />
        <v-menu :close-on-content-click="true" bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon large>
              <user-avatar
                size="40"
                :user_name="user.name"
                :avatar_url="user.avatar"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item>
                <v-list-item-avatar class="mr-2">
                  <user-avatar
                    size="40"
                    :user_name="user.name"
                    :avatar_url="user.avatar"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item to="perfil">
                <v-list-item-icon>
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $capitalize($tc("model.profile_page_title"))
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-main id="app_content" style="background-color: #f1f1f1">
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </v-main>
      <v-snackbar
        v-model="snackbar.active"
        :timeout="5000"
        left
        bottom
        :color="snackbar.color || ''"
      >
        {{ snackbar.text }}
        <!-- <v-btn color="white" text @click="closeSnack">Fechar</v-btn> -->
      </v-snackbar>
      <v-snackbars
        :messages="alert_reminders"
        :timeout="10000"
        color="white"
        light
        bottom
        right
      >
        <template v-slot:default="{ message }">
          <ReminderItem :reminder="message" no-actions />
        </template>
        <template v-slot:action="{ close, message }" class="align-self-end">
          <v-btn icon @click="closeReminder(close, message)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbars>

      <VideoCallNotifications />
    </v-app>
  </div>
</template>

<script>
import VSnackbars from "v-snackbars";
import ScopeProvider from "@/components/ScopeProvider";
import UserAvatar from "@/components/UserAvatar";
import ReminderMenu from "@/components/ReminderMenu";
import ReminderItem from "@/components/ReminderItem";
import { mapActions, mapState } from "vuex";
import AppSearchBar from "@/components/AppSearchBar";
import i18n from "@/mixins/i18n";
import LanguagePicker from "./LanguagePicker.vue";
import VideoCallNotifications from "@/components/video_calls/VideoCallNotifications";
import VideoCallsBarIcon from "./video_calls/VideoCallsBarIcon.vue";

export default {
  name: "HelloWorld",
  components: {
    ScopeProvider,
    UserAvatar,
    ReminderMenu,
    ReminderItem,
    VSnackbars,
    AppSearchBar,
    LanguagePicker,
    VideoCallNotifications,
    VideoCallsBarIcon,
  },
  mixins: [i18n],
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
    dialog: false,
    drawer: null,
    is_mini: true,
    items: [
      { icon: "mdi-view-dashboard", text: "Dashboard", link: "/" },
      {
        icon: "mdi-account-group",
        text: "Usuários",
        link: "/users",
        admin: true,
      },
    ],
    reminder_menu: false,
  }),
  methods: {
    closeSnack() {
      this.$store.commit("closeMessage");
    },
    changeLanguage(language) {
      this.$store.dispatch("localization/changeLanguage", language);
    },
    closeReminder(close, reminder) {
      this.$store.commit("reminder/removeFromAlertReminders", reminder);
      close();
    },
    ...mapActions("localization", ["loadLanguages"]),
    ...mapActions("info_requests", ["loadMyInfoRequests"]),
    ...mapActions("lead_notifications", [
      "loadLeadNotificationsAnalytics",
      "stopLeadNotificationAnalyticsPoll",
    ]),
    ...mapActions("reminder", [
      "loadActiveReminders",
      "loadInitialPreviousReminders",
    ]),
    ...mapActions("eps", ["loadActions"]),
  },
  created() {
    this.loadLanguages();
    this.loadLeadNotificationsAnalytics();
    this.loadMyInfoRequests();
    this.loadActiveReminders();
    this.loadInitialPreviousReminders();
    this.loadActions();
    Echo.private("user." + this.user.sub)
      .listen("AlertReminderNotificationEvent", (e) => {
        this.$store.dispatch("reminder/alertReminder", e);
      })
      .listen("ReminderRemovedEvent", (e) => {
        this.$store.commit("reminder/removeActiveReminder", e.id);
      })
      .listen("UserLeadNotificationsChanged", (e) => {
        this.$store.commit(
          "lead_notifications/SET_UNREAD_LEAD_NOTIFICATIONS",
          e.unread_notifications
        );
      })
      .listen("VideoCalls\\GuestCallingEvent", (e) => {
        this.$store.commit("video_calls/addAttempt", e);
      })
      .listen("VideoCalls\\AttemptCanceledEvent", (e) => {
        this.$store.commit("video_calls/removeAttempt", e);
        this.$store.commit("sendMessage", {
          text: "Ligação cancelada",
        });
      })
      .listen("VideoCalls\\VideoCallAnsweredEvent", (e) => {
        this.$store.commit("video_calls/removeAttempt", e);
        this.$store.commit("sendMessage", {
          text: "Ligação atendida em outra aba",
        });
      })
      .listen("VideoCalls\\AttemptRefusedEvent", (e) => {
        this.$store.commit("video_calls/removeAttempt", e);
        this.$store.commit("sendMessage", {
          text: "Ligação recusada em outra aba",
        });
      });
  },
  destroyed() {
    this.stopLeadNotificationAnalyticsPoll();
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
    language() {
      return this.user.language || {};
    },
    snackbar() {
      return this.$store.getters.getSnackbar;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION
        ? "v" + process.env.VUE_APP_VERSION
        : "dev";
    },
    ...mapState("localization", ["languages", "changing_language"]),
    ...mapState("info_requests", ["my_info_requests"]),
    ...mapState("lead_notifications", ["unread_lead_notifications"]),
    ...mapState("reminder", ["alert_reminders"]),
  },
};
</script>
