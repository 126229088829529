<template>
  <div style="overflow-y: hidden">
    <v-fade-transition>
      <router-view v-if="i18nLoaded"></router-view>
      <div v-else class="loader-container">
        <h1>
          <v-img src="@/assets/logo.png" width="170px"></v-img>
        </h1>
      </div>
    </v-fade-transition>
    <ConfirmDialog />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/tools/ConfirmDialog";
import { heartbeat } from "./services/monitoring";
import { languages_info } from "@/services/localizations";
import i18n from "@/i18n/index.js";

export default {
  name: "App",
  components: { ConfirmDialog },
  data: () => ({
    i18nLoaded: false,
    timedOut: null,
    heartbeatInterval: null,
    active: false,
    heartbeatDelay: 60000,
    timeoutDelay: 300000,
  }),
  methods: {
    refreshTimeout() {
      this.active = true;
      clearTimeout(this.timedOut);
      this.timedOut = setTimeout(() => {
        this.active = false;
      }, this.timeoutDelay);
    },

    heartbeat(force = false) {
      if (this.active || force) {
        heartbeat();
      }
    },

    async loadValidationMessages(lang, messages) {
      const loaded_validations = {};

      if (loaded_validations[lang] === undefined) {
        try {
          const locale = await import(`vee-validate/dist/locale/${lang}.json`);
          loaded_validations[lang] = locale.messages;
        } catch (e) {
          const lang_without_country = languages_info[lang].without_country;
          const locale = await import(
            `vee-validate/dist/locale/${lang_without_country}.json`
          );
          loaded_validations[lang] = locale.messages;
        }
      }

      messages.validation = loaded_validations[lang];
      return messages;
    },

    async i18nInit() {
      if (this.i18nLoaded) {
        return;
      }

      try {
        const response = await fetch("/storage/i18n/vue.json");
        const json = await response.json();

        for (const [key, value] of Object.entries(json)) {
          await this.loadValidationMessages(key, value).then((messages) => {
            i18n.setLocaleMessage(key, messages);
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.i18nLoaded = true;
      }
    },
  },

  watch: {
    active: function (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.heartbeatInterval = setInterval(
            this.heartbeat,
            this.heartbeatDelay
          );
        } else {
          clearInterval(this.heartbeatInterval);
        }
      }
    },
  },

  created() {
    this.refreshTimeout();

    document.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      this.refreshTimeout();
    });

    document.addEventListener("ontouchstart", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      this.refreshTimeout();
    });

    document.addEventListener("mousemove", this.refreshTimeout, false);

    this.i18nInit();
  },

  mounted() {
    setTimeout(this.heartbeat, 1000);
  },

  destroyed() {
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.timedOut);
    document.removeEventListener("keydown");
    document.removeEventListener("mousemove");
    document.removeEventListener("ontouchstart");
  },

  unmounted() {
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.timedOut);
    document.removeEventListener("keydown");
    document.removeEventListener("mousemove");
    document.removeEventListener("ontouchstart");
  },
};
</script>

<style>
/* html {
  overflow-y: auto;
} */

a.link {
  text-decoration: underline dotted var(--v-anchor-base) !important;
  text-underline-offset: 2px !important;
  text-decoration-thickness: 1px !important;
}

a.link:hover {
  font-weight: 500 !important;
}

.right-sheet {
  border-radius: 0 !important;
  margin: 0 !important;
  min-height: 100%;
  position: fixed;
  overflow-y: auto !important;
  top: 0;
  right: 0;
  display: flex;
}

.loader-container {
  transition: all 0.3s ease;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
