<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to {
  transform: scale(0.7);
  opacity: 0;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scale(1.3);
  opacity: 0;
}
</style>
<template>
  <v-app id="inspire">
    <v-main style="background-color: #f1f1f1">
      <v-container class="fill-height" style="max-width: 500px">
        <v-row align="center" justify="center" no-gutters>
          <transition name="slide-fade" slot="div">
            <v-col v-if="!logging_out">
              <ValidationObserver ref="forms" v-slot="{}">
                <v-form @submit.prevent="login">
                  <v-card class="pb-7" flat>
                    <v-card-text class="card-text">
                      <h1 class="text-center mb-10 mt-5">
                        <v-img
                          src="@/assets/logo.png"
                          width="220px"
                          class="mx-auto"
                        ></v-img>
                      </h1>

                      <v-alert
                        type="error"
                        class="mt-2 mb-6"
                        v-if="message"
                        text
                        icon="mdi-message-alert-outline"
                        >{{ message }}</v-alert
                      >

                      <ValidationProvider
                        name="auth.username"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :label="$capitalize($tc('model.auth_username_login'))"
                          name="email"
                          prepend-inner-icon="mdi-account"
                          type="text"
                          v-model="email"
                          :error-messages="errors"
                          :disabled="loading"
                          outlined
                        />
                      </ValidationProvider>

                      <ValidationProvider
                        name="auth.password"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          id="password"
                          :label="$capitalize($tc('model.auth_password_login'))"
                          name="password"
                          prepend-inner-icon="mdi-lock"
                          v-model="password"
                          :error-messages="errors"
                          :disabled="loading"
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show = !show"
                          outlined
                        />
                      </ValidationProvider>
                    </v-card-text>

                    <v-card-actions>
                      <!-- <v-spacer /> -->
                      <v-btn
                        type="submit"
                        color="primary"
                        :loading="loading"
                        large
                        class="mb-5 px-12 mx-auto"
                      >
                        <v-icon left>mdi-login</v-icon
                        >{{ $capitalize($tc("model.auth_login_button")) }}
                      </v-btn>
                    </v-card-actions>
                    <!-- <v-content>
                      <v-row align="center" justify="center">
                        <v-col class="text-center">
                          <router-link to="/forgot_password" large>Esqueci minha senha</router-link>
                        </v-col>
                      </v-row>
                    </v-content>-->
                  </v-card>
                </v-form>
              </ValidationObserver>
              <div class="mt-2 text-center text-caption">
                {{ appVersion }}
              </div>
            </v-col>
            <v-col v-else class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </transition>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "login",
  props: ["external_message"],
  data: () => ({
    email: "",
    password: "",
    show: false,
    message: "",
    loading: false,
  }),
  methods: {
    async login() {
      this.$refs.forms.validate().then(async (r) => {
        if (r) {
          this.loading = true;
          try {
            const response = await this.$store.dispatch("auth/login", {
              auth: {
                username: this.email,
                password: this.password,
              },
            });

            this.message = response.message;

            if (response.success) {
              this.$router.push({ name: "home" });
            }
          } catch (error) {
            this.message = error.response.data.message;
            this.$refs.forms.setErrors(error.response.data.errors);
            this.$store.commit("sendMessage", {
              message: "Falha ao realizar login, tente novamente mais tarde.",
              color: "red",
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
  async created() {},
  computed: {
    logging_out: function () {
      return this.$store.getters.getLoading;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION
        ? "v" + process.env.VUE_APP_VERSION
        : "dev";
    },
  },
};
</script>

<style lang="scss">
.login {
  .card-text {
    padding: 10px;
  }
}
</style>
