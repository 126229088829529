import Vue from "vue";

const base_uri = process.env.VUE_APP_BASE_PRODUCTS_API;

export async function index(params) {
  const response = await Vue.prototype.$http.get(
    `${base_uri}/local_categories`,
    {
      params,
    }
  );

  return response.data;
}
