import Vue from "vue";

const base_uri = process.env.VUE_APP_BASE_AUTH_API
  ? process.env.VUE_APP_BASE_AUTH_API
  : "";

export async function login(payload, code_challenge) {
  const response = await Vue.prototype.$http.post(
    base_uri + "/auth?code_challenge=" + code_challenge,
    payload
  );

  return response.data;
}

export async function authorize(authorization_token, code_verifier) {
  const response = await Vue.prototype.$http.post(base_uri + "/authorize", {
    authorization_token,
    code_verifier,
  });

  return response.data;
}

export async function logout(refresh_token) {
  const response = await Vue.prototype.$http.post(base_uri + "/logout", {
    refresh_token,
  });

  return response;
}

export async function introspection(access_token) {
  const response = await Vue.prototype.$http.post(base_uri + "/introspection", {
    access_token,
  });

  return response;
}

export async function refresh(refresh_token) {
  const response = await Vue.prototype.$http.post(base_uri + "/refresh", {
    refresh_token,
  });

  return response.data;
}
